var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('TextField',{attrs:{"placeholder":_vm.trans('LABEL:searchInput'),"custom-class":'pl-8'},scopedSlots:_vm._u([{key:"iconFirst",fn:function(){return [_c('base-icon',{staticClass:"absolute h-4 w-4 top-1/2 transform -translate-y-1/2 left-3",attrs:{"name":"search"}})]},proxy:true},{key:"iconEnd",fn:function(){return [_c('CloseButton',{attrs:{"show":!_vm.isEmpty(_vm.postsSearch)},on:{"clicked":_vm.clearSearchInput}})]},proxy:true}]),model:{value:(_vm.postsSearch),callback:function ($$v) {_vm.postsSearch=$$v},expression:"postsSearch"}}),_c('hr',{staticClass:"-mx-6 mt-5 mb-2 border-dashed border-grayedDark"}),_c('div',{staticClass:"text-base leading-normal "},_vm._l((_vm.postCategories),function(category,index){return _c('div',{key:category.slug,staticClass:"w-full"},[_c('div',{staticClass:"w-full py-3 group",class:{
            'hover:cursor-default': _vm.isCurrentCategory(category.slug),
            'cursor-pointer': !_vm.isCurrentCategory(category.slug)
          },on:{"click":function($event){_vm.isCurrentCategory(category.slug) ? null : _vm.goToCategory(category)}}},[_c('span',{class:{
              'font-semibold text-redAccent': _vm.isCurrentCategory(category.slug),
              'group-hover:text-redAccent':!_vm.isCurrentCategory(category.slug)
            }},[_vm._v(" "+_vm._s(_vm.trans(("POST_CATEGORY:" + (category.slug))))+" ")])]),(index !== _vm.postCategories.length - 1)?_c('hr',{staticClass:"w-full border-grayedHR mb-1"}):_vm._e()])}),0),_c('hr',{staticClass:"-mx-6 my-2 border-dashed border-grayedDark"}),_c('div',{staticClass:"text-base leading-normal "},_vm._l((_vm.notCategories),function(category,index){return _c('div',{key:category.slug},[_c('div',{staticClass:"py-3 group",class:{
            'hover:cursor-default': _vm.isCurrentCategory(category.slug),
            'cursor-pointer': !_vm.isCurrentCategory(category.slug)
          },on:{"click":function($event){_vm.isCurrentCategory(category.slug) ? null : _vm.goToCategory(category)}}},[_c('span',{class:{
              'font-semibold text-redAccent': _vm.isCurrentCategory(category.slug),
              'group-hover:text-redAccent':!_vm.isCurrentCategory(category.slug)
            }},[_vm._v(" "+_vm._s(_vm.trans(("POST_CATEGORY:" + (category.slug))))+" ")])]),(index !== _vm.notCategories.length - 1)?_c('hr',{staticClass:"border-grayedHR mb-1"}):_vm._e()])}),0),_c('hr',{staticClass:"-mx-6 my-2 border-dashed border-grayedDark"}),_c('div',{staticClass:"text-base leading-normal "},_vm._l((_vm.contactCategory),function(category,index){return _c('div',{key:category.slug},[_c('div',{staticClass:"pt-3 group",class:{
            'hover:cursor-default': _vm.isCurrentCategory(category.slug),
            'cursor-pointer': !_vm.isCurrentCategory(category.slug)
          },on:{"click":function($event){_vm.isCurrentCategory(category.slug) ? null : _vm.goToCategory(category)}}},[_c('span',{class:{
              'font-semibold text-redAccent': _vm.isCurrentCategory(category.slug),
              'group-hover:text-redAccent':!_vm.isCurrentCategory(category.slug)
            }},[_vm._v(" "+_vm._s(_vm.trans(("POST_CATEGORY:" + (category.slug))))+" ")])])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }