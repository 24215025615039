<template>
  <PageContainer no-loader>
    <PageTitle>
	    <div class="flex flex-row items-center gap-1" v-if="currentCategory && currentCategory.slug && !$route.query.search">
        <span v-if="!postData && currentCategory" :class="{'text-grayed': postData}">
          {{ trans('POST_CATEGORY:' + currentCategory.slug) }}
        </span>
		    <span v-else-if="currentCategory.onePost" >
			    {{ trans('POST_CATEGORY:' + currentCategory.slug) }}
		    </span>
		    <router-link v-else :to="{name: 'posts', params: {category: currentCategory.slug}, query: {page: lastPage}}" class="hover:opacity-70 hover:text-redAccentLight font-bold text-left m-0 py-0 cursor-pointer">
			    {{ trans('POST_CATEGORY:' + currentCategory.slug) }}
		    </router-link>
		    <template v-if="!currentCategory.onePost">
			    <base-icon v-if="postData" name="chevronRight"></base-icon>
			    <span v-if="postData" class="text-grayed">
            <span class="lg:hidden">
             {{ excerpt(postData.title,25) }}
            </span>
            <span class="hidden lg:block">
             {{ excerpt(postData.title,50) }}
            </span>
		      </span>
		    </template>
	    </div>
	    <div v-else class="flex flex-row items-center gap-1">
		    <router-link :to="{name: 'posts', query: {page: lastPage, search: searchQuery}}" class="hover:opacity-70 hover:text-redAccentLight font-bold text-left m-0 py-0 cursor-pointer">
			    {{ trans('LABEL:PostSearchResult') }}: {{ $route.query.search }}
		    </router-link>
		    <base-icon v-if="postData" name="chevronRight"></base-icon>
		    <span v-if="postData" class="text-grayed">
          <span class="lg:hidden">
	         {{ excerpt(postData.title,25) }}
          </span>
          <span class="hidden lg:block">
	         {{ excerpt(postData.title,50) }}
          </span>
        </span>
	    </div>
    </PageTitle>
    <div class="w-full flex flex-col lg:flex-row items-start">
      <div v-if="!isMobile" class="w-full lg:w-sidebar lg:pr-6">
        <t-card class="w-full">
          <PostsNavigation/>
        </t-card>
      </div>
      <div class="w-full lg:w-main-container">
        <router-view class="w-full">
        </router-view>
      </div>
    </div>
  </PageContainer>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Button from "@/utils/crud/components/Button";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import Pagination from "@/views/content/components/Pagination";
import Loader from "@/views/Loader";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import BaseIcon from "@/utils/icons/BaseIcon";
import TextField from "@/utils/crud/components/field-types/Text";
import PostCard from "@/views/content/posts/components/PostCard";
import PostsNavigation from "@/views/content/posts/components/PostsNavigation";
import {excerpt} from "@/common/helpers/utils";

export default {
  name: "ReadingRoom",
  components: {
    PostsNavigation,
    PostCard, TextField, BaseIcon, PageTitle, PageContainer, Loader, Button, Pagination},
  computed: {
    ...mapGetters('posts', ['postsData', 'postData', 'pagination', 'loadingPosts', 'postsLoaded', 'currentCategory', 'lastPage', 'searchQuery']),
    ...mapGetters('dictionaries', ['categoriesData', ]),
  },
  data() {
    return {
      isMobile: checkIsMobileDevice(),
      excerpt: excerpt,
    }
  },
  methods: {
    ...mapActions('posts', ['fetchPostsRequest']),
    ...mapActions('dictionaries', ['fetchCategoriesData']),
  },
  mounted() {
		if(!this.$route.params.category) {
			this.$router.push({name: 'post', params: {category: 'news'}})
		}
  },

}
</script>
