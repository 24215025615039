<template>
  <div class="w-full">
    <!-- Search -->
    <TextField v-model="postsSearch" :placeholder="trans('LABEL:searchInput')" :custom-class="'pl-8'">
      <template v-slot:iconFirst>
        <base-icon class="absolute h-4 w-4 top-1/2 transform -translate-y-1/2 left-3" name="search">
        </base-icon>
      </template>
      <template v-slot:iconEnd>
	      <CloseButton @clicked="clearSearchInput" :show="!isEmpty(postsSearch)" />
      </template>
    </TextField>
    <!-- Posts -->
    <hr class="-mx-6 mt-5 mb-2 border-dashed border-grayedDark" />
    <div class="text-base leading-normal ">
      <div v-for="(category, index) in postCategories" :key="category.slug" class="w-full">
        <div class="w-full py-3 group"
          :class="{
            'hover:cursor-default': isCurrentCategory(category.slug),
            'cursor-pointer': !isCurrentCategory(category.slug)
          }"
          @click="isCurrentCategory(category.slug) ? null : goToCategory(category)"
        >
          <span
            :class="{
              'font-semibold text-redAccent': isCurrentCategory(category.slug),
              'group-hover:text-redAccent':!isCurrentCategory(category.slug)
            }"
          >
            {{ trans(`POST_CATEGORY:${category.slug}`) }}
          </span>
        </div>
        <hr v-if="index !== postCategories.length - 1" class="w-full border-grayedHR mb-1" />
      </div>
    </div>
    <!-- Single pages but contact -->
    <hr class="-mx-6 my-2 border-dashed border-grayedDark" />
    <div class="text-base leading-normal ">
      <div v-for="(category, index) in notCategories" :key="category.slug">
        <div class="py-3 group"
             :class="{
            'hover:cursor-default': isCurrentCategory(category.slug),
            'cursor-pointer': !isCurrentCategory(category.slug)
          }"
          @click="isCurrentCategory(category.slug) ? null : goToCategory(category)"
        >
          <span
            :class="{
              'font-semibold text-redAccent': isCurrentCategory(category.slug),
              'group-hover:text-redAccent':!isCurrentCategory(category.slug)
            }"
          >
            {{ trans(`POST_CATEGORY:${category.slug}`) }}
          </span>
        </div>
        <hr v-if="index !== notCategories.length - 1" class="border-grayedHR mb-1" />
      </div>
    </div>
    <!-- Contact -->
    <hr class="-mx-6 my-2 border-dashed border-grayedDark" />
    <div class="text-base leading-normal ">
      <div v-for="(category, index) in contactCategory" :key="category.slug">
        <div class="pt-3 group"
             :class="{
            'hover:cursor-default': isCurrentCategory(category.slug),
            'cursor-pointer': !isCurrentCategory(category.slug)
          }"
          @click="isCurrentCategory(category.slug) ? null : goToCategory(category)"
        >
          <span
            :class="{
              'font-semibold text-redAccent': isCurrentCategory(category.slug),
              'group-hover:text-redAccent':!isCurrentCategory(category.slug)
            }"
          >
            {{ trans(`POST_CATEGORY:${category.slug}`) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextField from "@/utils/crud/components/field-types/Text";
import BaseIcon from "@/utils/icons/BaseIcon";
import {mapActions, mapGetters, mapMutations} from "vuex";
import router from "@/router";
import staticSites from "@/router/app/staticSites";
import debounce from 'lodash/debounce';
import {isEmpty} from "lodash/lang";
import CloseButton from "@/utils/crud/components/CloseButton.vue";

export default {
  name: "PostsNavigation",
  components: {CloseButton, BaseIcon, TextField},
  data() {
    return {
      staticPages: staticSites,
      postsSearch: null,
	    isEmpty: isEmpty,
    }
  },
  watch: {
	  postsSearch: {
			handler(val) {
				if(val) {
					this.searchPostsAction();
				}
			}
	  }
  },
  computed: {
    ...mapGetters('dictionaries', ['categoriesData', ]),
    ...mapGetters('posts', ['searchQuery']),

    postCategories() {
      if(this.categoriesData) {
        return this.categoriesData.filter(el => el.slug === 'news' || el.slug === 'faq');
      }
    },
    notCategories() {
      if(this.categoriesData) {
        return this.categoriesData.filter(el => el.slug !== 'news' && el.slug !== 'faq' && el.slug !== 'contact' && el.slug !== 'empty');
      }
    },
    contactCategory() {
      if(this.categoriesData) {
        return this.categoriesData.filter(el => el.slug === 'contact');
      }
    }
  },
  methods: {
    ...mapActions('posts', ['fetchPostsRequest']),
    ...mapActions('dictionaries', ['fetchCategoriesData']),
    ...mapMutations('posts', ['setSearchQuery']),
    goToCategory(category) {
      if(category.hasOwnProperty('onlyPostSlug')) {
        router.push({name: 'post-show', params: {category: category.slug, slug: category.onlyPostSlug}})
        return;
      }
      router.push({name: 'posts', params: {category: category.slug}, query: {page: 1}})
    },
    isCurrentCategory(slug) {
      return this.$route.params.category === slug;
    },
    goToPage(page) {
      router.push({name: page})
    },
    isCurrentPage(name) {
      return this.$route.name === name;
    },
    searchPostsAction: debounce(function (e) {
			if(this.postsSearch) {
				this.setSearchQuery(this.postsSearch);
				this.$router.push({name: 'posts', query: {page: 1, search: this.postsSearch}})
			}
    }, 500),

    clearSearchInput() {
      this.postsSearch = null;
	    this.setSearchQuery(null);
	    this.$router.push({name: 'posts', params: {category: 'news'}, query: { page: 1}});
    }
  },
  mounted() {

  }
}
</script>

